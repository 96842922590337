import Link from 'next/link';
import React from 'react';
import styles from '../styles/layout/_beforeloginheader.module.scss';

const BeforeLoginHeader = () => {
  return (
    <div className={`${styles.loginHeader} ${styles.afterBg}`}>
      <div className="container">
        <Link href="/">
          <img src="/assets/images/logo.svg" alt="" />
        </Link>
      </div>
    </div>
  );
};

export default BeforeLoginHeader;
