import * as React from 'react';

import NormalHeader from './NormalHeader';
import BeforeLoginHeader from './BeforeLoginHeader';
import DashboardHeader from './DashboardHeader';

const pages = ['Home', 'About', 'FAQs', 'Contact Us', 'Login'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = ({ type = 'normal' }) => {
  return (
    <>
      {type === 'normal' && <NormalHeader />}
      {type === 'dashboard' && <DashboardHeader />}
      {type === 'before_login' && <BeforeLoginHeader />}
    </>
  );
};
export default ResponsiveAppBar;
