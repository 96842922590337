import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import styles from '../styles/layout/_header.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
// import { Router } from "@mui/icons-material";
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
// const pages = ['Home', 'About', 'FAQs', 'Contact Us', 'Login'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const NormalHeader = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { successfull_Login_status } = useSelector((state) => state.UserSlice);
  const router = useRouter();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages = React.useMemo(() => {
    const isLogedIn = new Cookies().get('token');
    return [
      {
        path: '/',
        value: 'Home'
      },
      {
        path: '/about',
        value: 'About'
      },
      {
        path: '/faqs',
        value: 'FAQs'
      },
      {
        path: '/contact',
        value: 'Contact Us'
      },
      {
        path: '/Subcripstion',
        value: 'Subscription plan'
      },
      {
        path: '/login',
        value: isLogedIn ? 'View My Dashboard' : 'Login'
      }
    ];
  }, [successfull_Login_status]);

  return (
    <AppBar position="static" className={styles.page_header}>
      <div className="container">
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            className={styles.mobile_header}
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.header_logo}>
            <Link href="/">
              <img src="/assets/images/logo.svg" alt="" />
            </Link>
          </div>

          <Box sx={{ flexGrow: 1 }} className={styles.header_menu}>
            {pages.map((page) => (
              <Button
                onClick={() => router.push(page.path)}
                sx={{ my: 2, display: 'block' }}
                className={router.pathname === page.path && styles.active_menu}
              >
                {page.value}
              </Button>
            ))}
          </Box>

          <Box component="nav">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 }
              }}
              className={styles.mobile_nav}
            >
              {pages.map((page) => (
                <Button
                  onClick={() => router.push(page.path)}
                  sx={{ my: 2, display: 'block' }}
                  className={
                    router.pathname === page.path && styles.active_menu_mobile
                  }
                >
                  {page.value}
                </Button>
              ))}
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip> */}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </div>
    </AppBar>
  );
};
export default NormalHeader;
